import React from "react";
import { Trans } from "react-i18next";

import CrossHairIcon from "@/inline-assets/accolade-crosshair.svg";
import AccoladeDamageDealt from "@/inline-assets/hextech-accolade-damage-dealt.svg";
import { DataPointTag } from "@/shared/Accolades.jsx";

const getKillAccolades = ({ matchVal, avgVal, accolades, t }) => {
  const icon = <CrossHairIcon />;
  if (avgVal > 0) {
    if (matchVal > avgVal) {
      const diff = t("common:percent", "{{percent, percent}}", {
        percent: matchVal / avgVal - 1,
      });
      const summaryComponent = () => (
        <>
          <DataPointTag
            color={"var(--blue)"}
            title={t("common:thisMatch", "This Match")}
            dataPoint={`${matchVal.toFixed(1)}`}
            showArrow
            didBetter
          />
          <DataPointTag
            title={t("common:stats.recentAvg", "Recent Avg.")}
            dataPoint={`${avgVal.toFixed(1)}`}
          />
        </>
      );

      accolades.good.push({
        icon,
        title: t("common:stats.kills", "Kills"),
        description: (
          <Trans i18nKey="apex:accolades.yourKillsBetter">
            Your kills were <span>{{ diff }} better</span> than your recent 20
            average.
          </Trans>
        ),
        summaryComponent,
      });
    } else if (matchVal <= avgVal) {
      const diff = t("common:percent", "{{percent, percent}}", {
        percent: 1 - matchVal / avgVal,
      });
      const summaryComponent = () => (
        <>
          <DataPointTag
            color={"var(--blue)"}
            title={t("common:thisMatch", "This Match")}
            dataPoint={`${matchVal.toFixed(1)}`}
            showArrow
          />
          <DataPointTag
            title={t("common:stats.recentAvg", "Recent Avg.")}
            dataPoint={`${avgVal.toFixed(1)}`}
          />
        </>
      );

      accolades.bad.push({
        icon,
        title: t("common:stats.kills", "Kills"),
        description: (
          <Trans i18nKey="apex:accolades.yourKillsWorse">
            Your kills were <span>{{ diff }} worse</span> than your recent 20
            average.
          </Trans>
        ),
        summaryComponent,
      });
    }
  }
};

const getDamageAccolades = ({ matchVal, avgVal, accolades, t }) => {
  const icon = <AccoladeDamageDealt />;
  if (avgVal > 0) {
    if (matchVal > avgVal) {
      const diff = t("common:percent", "{{percent, percent}}", {
        percent: matchVal / avgVal - 1,
      });
      const summaryComponent = () => (
        <>
          <DataPointTag
            color={"var(--blue)"}
            title={t("common:thisMatch", "This Match")}
            dataPoint={`${matchVal.toFixed(1)}`}
            showArrow
            didBetter
          />
          <DataPointTag
            title={t("common:stats.recentAvg", "Recent Avg.")}
            dataPoint={`${avgVal.toFixed(1)}`}
          />
        </>
      );

      accolades.good.push({
        icon,
        title: t("common:stats.damage", "Damage"),
        description: (
          <Trans i18nKey="apex:accolades.yourDmgBetter">
            Your damage was <span>{{ diff }} better</span> than your recent 20
            average.
          </Trans>
        ),
        summaryComponent,
      });
    } else if (matchVal <= avgVal) {
      const diff = t("common:percent", "{{percent, percent}}", {
        percent: 1 - matchVal / avgVal,
      });
      const summaryComponent = () => (
        <>
          <DataPointTag
            color={"var(--blue)"}
            title={t("common:thisMatch", "This Match")}
            dataPoint={`${matchVal.toFixed(1)}`}
            showArrow
          />
          <DataPointTag
            title={t("common:stats.recentAvg", "Recent Avg.")}
            dataPoint={`${avgVal.toFixed(1)}`}
          />
        </>
      );

      accolades.bad.push({
        icon,
        title: t("common:stats.damage", "Damage"),
        description: (
          <Trans i18nKey="apex:accolades.yourDmgWorse">
            Your damage was <span>{{ diff }} worse</span> than your recent 20
            average.
          </Trans>
        ),
        summaryComponent,
      });
    }
  }
};

export const buildAccolades = ({
  t,
  matchStats = {},
  last20MatchStats: last20Matches = {},
}) => {
  const accolades = {
    good: [],
    bad: [],
    fancy: [],
  };

  // For Kills
  const avgKills = last20Matches.kills;
  const matchKills = matchStats.kills;
  getKillAccolades({ matchVal: matchKills, avgVal: avgKills, accolades, t });

  // For Damage
  const avgDmg = last20Matches.damage_done;
  const matchDmg = matchStats.damage_done;
  getDamageAccolades({ matchVal: matchDmg, avgVal: avgDmg, accolades, t });

  return accolades;
};
