import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { Card } from "clutch/src/Card/Card.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { formatDuration } from "@/app/util.mjs";
import { buildAccolades } from "@/game-apex/Accolades.jsx";
import ProfileWeapons from "@/game-apex/components/ProfileWeapons.jsx";
import { GAME_MODES } from "@/game-apex/constants.mjs";
import Scoreboard from "@/game-apex/Scoreboard.jsx";
import staticMediaURLs from "@/game-apex/static.mjs";
import useApexLast20 from "@/game-apex/useApexLast20.jsx";
import {
  calcPlayerMatchStats,
  calcWeaponsAccuracy,
  getPlayerStatsByMatch,
} from "@/game-apex/utils.mjs";
import CrossHairIcon from "@/inline-assets/accolade-crosshair.svg";
import {
  Accolade,
  AccoladeHeader,
  EmptyAccolade,
  LiveAccolade,
} from "@/shared/Accolades.jsx";
import DataAccuracyWarning from "@/shared/DataAccuracyWarning.jsx";
import { SharedMatchLayout } from "@/shared/Match.jsx";
import { MainColumnsContainer } from "@/shared/Match.style.jsx";
import { HeaderRankPoints } from "@/shared/Profile.style.jsx";
import ShareButton from "@/shared/ShareButton.jsx";
import SharedStatsBreakdown from "@/shared/StatsBreakdown.jsx";
import { TimeAgo } from "@/shared/Time.jsx";
import HitStats from "@/shared-fps/HitStats.jsx";
import { greaterThanAndNotEq, toFixedNumber } from "@/util/helpers.mjs";
import { formatToPercent } from "@/util/i18n-helper.mjs";
import orderArrayBy from "@/util/order-array-by.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const Subtitle = styled("p")`
  display: flex;
  gap: var(--sp-3);
  color: var(--shade2);
  white-space: nowrap;
`;

const Match = () => {
  const route = useRoute();
  const { parameters: [profileId, , matchId] = [] } = route || {};

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const state = useSnapshot(readState);
  const profile = state.apex?.profiles?.[profileId];

  const isLivePage = false;
  const liveGame = state.apex.liveGame;
  const weapons = state.apex.meta?.weapons;
  const match = state.apex.matches[matchId];

  const isLive = isLivePage && liveGame;
  const isAlive = isLive;
  const { gameStartedAt, season: { apexId } = {}, gameMode } = match || {};

  const { last20Stats } = useApexLast20({
    profileId,
    season: apexId,
    mode: gameMode,
  });

  const {
    myPlayer,
    placement,
    minutesplayed,
    headerIconUrl,
    mode,
    hasHitStats,
  } = useMemo(() => {
    const myPlayer = getPlayerStatsByMatch(match, profileId);
    const modeObj = GAME_MODES[match?.gameMode || "ALL"];
    const mode = modeObj ? t(modeObj.t, modeObj.label) : "";
    const hasHitStats = !!myPlayer?.hits;

    return {
      myPlayer,
      minutesplayed: (myPlayer?.survivalTime || 0) * 1000,
      headerIconUrl: staticMediaURLs.getLegendImage(myPlayer?.champion?.apexId),
      modeObj,
      mode,
      placement: myPlayer?.team?.placement,
      hasHitStats,
    };
  }, [match, profileId, t]);

  const gameAlert = useMemo(() => {
    if (["ARENAS", "RANKED_ARENAS"].includes(gameMode)) {
      return t(
        "apex:stats.unavailableForMode",
        "Stats related to Accuracy and Headshot are unavailable in this mode.",
      );
    }
  }, [gameMode, t]);

  const title =
    isLive || !placement
      ? profile?.username
      : placement === 1
        ? t("lol:postmatch.victory", "Victory")
        : t("common:ordinalPlace", "{{place, ordinal}} Place", {
            place: placement,
          });

  const hasStats = !isAlive && myPlayer && minutesplayed > 0;

  const matchHitStats = useMemo(() => {
    const hitStats = {
      kills: myPlayer?.kills || 0,
      weaponHits: {
        headshots: myPlayer?.headshots || 0,
        bodyshots: myPlayer?.hits
          ? myPlayer?.hits - (myPlayer?.headshots || 0)
          : 0,
      },
    };

    return hitStats;
  }, [myPlayer]);

  const lastMatchesHitStats = useMemo(() => {
    const {
      headshotPercentage: headshots = 0,
      matchesWithHits = 0,
      hits,
    } = last20Stats;
    const lastHitStats = {
      matches: matchesWithHits,
      weaponHits: {
        headshots: headshots * hits,
        bodyshots: (1 - headshots) * hits,
      },
    };
    return lastHitStats;
  }, [last20Stats]);

  const { accolades, sections, rowData } = useMemo(() => {
    const accolades =
      hasStats &&
      buildAccolades({
        t,
        matchStats: myPlayer || {},
        last20MatchStats: last20Stats || {},
      });

    const sections = [
      {
        centerTitleText: t("common:statistic", "Statistic"),
        leftTitleText: t("common:thisMatch", "This Match"),
        rightTitleText: t(
          "common:recentnAvg",
          "Recent {{numberOfGames}} Avg.",
          {
            numberOfGames: last20Stats.matches,
          },
        ),
      },
    ];
    const rowData = myPlayer
      ? [
          [
            {
              title: t("common:stats.kills", "Kills"),
              dataPoint: myPlayer?.kills || 0,
              recentAvg: toFixedNumber(last20Stats?.kills, 1) || 0,
            },
            {
              title: t("common:stats.assists", "Assists"),
              dataPoint: myPlayer?.assists || 0,
              recentAvg: toFixedNumber(last20Stats?.assists, 1) || 0,
            },
            {
              title: t("common:stats.revives", "Revives"),
              dataPoint: myPlayer?.revivesGiven || 0,
              recentAvg: toFixedNumber(last20Stats?.revivesGiven, 1) || 0,
            },
            {
              title: t("common:stats.damage", "Damage"),
              dataPoint: myPlayer?.damage_done || 0,
              recentAvg: toFixedNumber(last20Stats?.damage_done, 1) || 0,
            },
            {
              title: t("common:stats.knockDowns", "Knock Downs"),
              dataPoint: myPlayer?.knockdowns || 0,
              recentAvg: last20Stats?.knockdowns || 0,
            },
            ...(typeof myPlayer.shots === "number"
              ? [
                  {
                    title: t("common:stats.accuracy", "Accuracy"),
                    dataPoint: t("common:percent", "{{percent, percent}}", {
                      percent: calcWeaponsAccuracy(
                        myPlayer?.playerMatchWeaponStats,
                        weapons,
                      ),
                    }),
                    recentAvg: t("common:percent", "{{percent, percent}}", {
                      percent: last20Stats?.accuracy || 0,
                    }),
                    didBetter: (a, b) =>
                      greaterThanAndNotEq(parseFloat(a), parseFloat(b)),
                  },
                  {
                    title: t("common:stats.headshots", "Headshots"),
                    dataPoint: formatToPercent(
                      language,
                      myPlayer?.headshots / myPlayer?.hits || 0,
                    ),
                    recentAvg: formatToPercent(
                      language,
                      last20Stats?.headshotPercentage,
                    ),
                    didBetter: (a, b) =>
                      greaterThanAndNotEq(parseFloat(a), parseFloat(b)),
                  },
                ]
              : []),
          ],
        ]
      : [];

    return {
      accolades,
      sections,
      rowData,
    };
  }, [hasStats, t, myPlayer, last20Stats, weapons, language]);

  const scoreboardInfo = useMemo(() => {
    const teams = match?.playerMatchStats?.reduce((acc, player) => {
      const teamId = player.team?.apexId;
      acc[teamId] = acc[teamId] || {
        players: [],
        totalKills: 0,
      };
      acc[teamId].totalKills += player.kills || 0;
      if (teamId === myPlayer?.team?.apexId) acc[teamId].isMyTeam = true;

      const playerStats = {
        ...calcPlayerMatchStats(player),
        isUser: player.platformId === myPlayer?.platformId,
      };
      acc[teamId].players.push(playerStats);
      acc[teamId].players = orderArrayBy(acc[teamId].players, "kills", "desc");
      return acc;
    }, {});
    return (
      teams &&
      orderArrayBy(
        Object.values(teams),
        ["isMyTeam", "totalKills"],
        ["desc", "desc"],
      )
    );
  }, [match?.playerMatchStats, myPlayer?.platformId, myPlayer?.team?.apexId]);

  const headerIconLink = useMemo(() => {
    if (!profileId) return "/apex";
    return `/apex/profile/${profileId}`;
  }, [profileId]);

  return (
    <SharedMatchLayout
      match={match}
      title={title}
      image={headerIconUrl}
      imageLink={headerIconLink}
      className="span-3"
      rightTitle={
        typeof myPlayer?.rankedPoints === "number" ? (
          <HeaderRankPoints rp={myPlayer?.rankedPoints}>
            {t("apex:stats.lpWithPoints", "{{lp, number}} LP", {
              lp: myPlayer?.rankedPoints,
              formatParams: { signDisplay: "always" },
            })}
          </HeaderRankPoints>
        ) : null
      }
      underTitle={
        <Subtitle className="type-body2">
          <span>{mode}</span>
          <span>{formatDuration(minutesplayed, "m:ss")}</span>
          <TimeAgo date={gameStartedAt * 1000} />
          <ShareButton />
        </Subtitle>
      }
    >
      <MainColumnsContainer>
        <Card
          title={t("common:analysis", "Analysis")}
          headerControls={<CrossHairIcon width={20} />}
          padding="0"
        >
          {isAlive ? <LiveAccolade /> : null}
          {!isAlive &&
            (!hasStats ||
              (accolades?.bad.length === 0 &&
                accolades?.good.length === 0)) && <EmptyAccolade />}
          {accolades?.bad?.map((a) => (
            <Accolade
              key={a.title}
              background={`var(--shade8-75)`}
              icon={a.icon}
            >
              <AccoladeHeader title={a.title} info={a.description} />
              {a.summaryComponent()}
            </Accolade>
          ))}
          {accolades?.good?.map((a) => (
            <Accolade
              key={a.title}
              background={`var(--shade8-75)`}
              icon={a.icon}
              didBetter
            >
              <AccoladeHeader title={a.title} info={a.description} />
              {a.summaryComponent()}
            </Accolade>
          ))}
        </Card>
        <div className="span-2">
          {gameAlert && (
            <DataAccuracyWarning type="alert" content={gameAlert} />
          )}
          {hasHitStats && (
            <HitStats
              comparisonStats={lastMatchesHitStats}
              matchStats={matchHitStats}
            />
          )}
          {hasStats && (
            <SharedStatsBreakdown rowData={rowData} sections={sections} />
          )}
          <ProfileWeapons />
          {scoreboardInfo?.length ? (
            <Card padding="0">
              <Scoreboard teams={scoreboardInfo} />
            </Card>
          ) : null}
        </div>
      </MainColumnsContainer>
    </SharedMatchLayout>
  );
};

export function meta([profileId, _, _matchId]) {
  const profile = readState.apex.profiles[profileId];

  return {
    title: [
      `apex:meta.postmatch.title`,
      `{{userName}}'s Apex Match Performance`,
      { userName: profile?.username },
    ],
    description: [
      `apex:meta.postmatch.description`,
      `View {{userName}}'s Apex match statistics and how they performed.`,
      { userName: profile?.username },
    ],
  };
}

export default Match;
